<template>
  <div v-if="authUserIsAdmin" class="row">
    <div class="col-12 col-md-3">
      <AdminPanelSummary></AdminPanelSummary>
    </div>
    <div class="col-12 col-md-9">
      <div class="row gy-3">
        <div class="col-12">
          <AdminPanelUserHistory class="my-3xxx"></AdminPanelUserHistory>
        </div>
        <div class="col-12">
          <AdminPanelSessionHistory class="my-3xxx"></AdminPanelSessionHistory>
        </div>
        <div class="col-12">
          <AdminPanelCommentHistory class="my-3xxx"></AdminPanelCommentHistory>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AdminStats',
  components: {
    AdminPanelSummary: () => import('@/components/admin/panels/AdminPanelSummary'),
    AdminPanelSessionHistory: () => import('@/components/admin/panels/AdminPanelSessionHistory'),
    AdminPanelCommentHistory: () => import('@/components/admin/panels/AdminPanelCommentHistory'),
    AdminPanelUserHistory: () => import('@/components/admin/panels/AdminPanelUserHistory'),
  },
  computed: {
    ...mapGetters('auth', ['authUserIsAdmin']),
  },
};
</script>
